
import { Toaster } from "@/components/ui/toaster";
import { Toaster as Sonner } from "@/components/ui/sonner";
import { TooltipProvider } from "@/components/ui/tooltip";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { lazy, Suspense, useEffect } from "react";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import ScrollToTop from "./components/ScrollToTop";

// Import i18n configuration
import "./i18n/i18n";

// Create a queryClient instance with optimal settings for performance
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 60 * 1000, // 1 minute
      retry: 1,
      refetchOnWindowFocus: false, // Disable refetching on window focus for better performance
      gcTime: 5 * 60 * 1000, // 5 minutes garbage collection time (formerly cacheTime)
    },
  },
});

// Eagerly load Index page for fastest homepage performance
import Index from "./pages/Index";

// Lazy load other pages to improve initial load performance
const NotFound = lazy(() => import("./pages/NotFound"));
const PricingPage = lazy(() => import("./pages/Pricing"));
const SupportPage = lazy(() => import("./pages/Support"));
const SoundProduction = lazy(() => import("./pages/SoundProduction"));
const AboutUs = lazy(() => import("./pages/AboutUs"));
const MixAndMaster = lazy(() => import("./pages/MixAndMaster"));
const VFX = lazy(() => import("./pages/VFX"));
const Beats = lazy(() => import("./pages/Beats"));

// Simplified redirect prevention component that just logs
const RedirectPrevention = () => {
  useEffect(() => {
    // Only log the current path for debugging
    console.log("Current path in App.tsx:", window.location.pathname);
  }, []);
  
  return null;
};

// Loading fallback component
const PageLoading = () => (
  <div className="min-h-screen flex items-center justify-center">
    <div className="w-8 h-8 border-4 border-gold border-t-transparent rounded-full animate-spin"></div>
  </div>
);

const App = () => (
  <QueryClientProvider client={queryClient}>
    <HelmetProvider>
      <TooltipProvider>
        <Helmet>
          <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0" />
        </Helmet>
        <Toaster />
        <Sonner />
        <BrowserRouter>
          <RedirectPrevention />
          <ScrollToTop />
          <Routes>
            {/* Use regular component for Index (homepage) for fastest loading */}
            <Route path="/" element={<Index />} />
            {/* Wrap lazy-loaded routes in Suspense */}
            <Route path="/pricing" element={
              <Suspense fallback={<PageLoading />}>
                <PricingPage />
              </Suspense>
            } />
            <Route path="/support" element={
              <Suspense fallback={<PageLoading />}>
                <SupportPage />
              </Suspense>
            } />
            <Route path="/sound-production" element={
              <Suspense fallback={<PageLoading />}>
                <SoundProduction />
              </Suspense>
            } />
            <Route path="/about-us" element={
              <Suspense fallback={<PageLoading />}>
                <AboutUs />
              </Suspense>
            } />
            <Route path="/mix-and-master" element={
              <Suspense fallback={<PageLoading />}>
                <MixAndMaster />
              </Suspense>
            } />
            <Route path="/vfx" element={
              <Suspense fallback={<PageLoading />}>
                <VFX />
              </Suspense>
            } />
            {/* Route for /beats */}
            <Route path="/beats" element={
              <Suspense fallback={<PageLoading />}>
                <Beats />
              </Suspense>
            } />
            <Route path="/beats/*" element={<Navigate to="/" replace />} />
            {/* Redirect /beatstore to the homepage */}
            <Route path="/beatstore" element={<Navigate to="/" replace />} />
            <Route path="/beatstore/*" element={<Navigate to="/" replace />} />
            {/* ADD ALL CUSTOM ROUTES ABOVE THE CATCH-ALL "*" ROUTE */}
            <Route path="*" element={
              <Suspense fallback={<PageLoading />}>
                <NotFound />
              </Suspense>
            } />
          </Routes>
        </BrowserRouter>
      </TooltipProvider>
    </HelmetProvider>
  </QueryClientProvider>
);

export default App;
