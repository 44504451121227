
import React, { useState, useEffect } from 'react';
import { Menu, X, Globe } from 'lucide-react';
import { useOnScrollUp } from '../hooks/useOnScrollUp';
import { Link, useLocation } from 'react-router-dom';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { useTranslation } from 'react-i18next';
import { countryToLanguageMap } from '../i18n/i18n';

interface NavigationProps {
  className?: string;
  dropdownBgClass?: string; // Prop for dropdown background
}

// List of countries with their names, codes for flag display, and language names
// Sorted by most popular languages internationally used on the web
const languages = [
  { country: "United States", code: "us", language: "English" },
  { country: "China", code: "cn", language: "中文" },
  { country: "Spain", code: "es", language: "Español" },
  { country: "United Kingdom", code: "gb", language: "English (UK)" },
  { country: "India", code: "in", language: "हिन्दी" },
  { country: "France", code: "fr", language: "Français" },
  { country: "Japan", code: "jp", language: "日本語" },
  { country: "Russia", code: "ru", language: "Русский" },
  { country: "Germany", code: "de", language: "Deutsch" },
  { country: "Brazil", code: "br", language: "Português" },
  { country: "Mexico", code: "mx", language: "Español" },
  { country: "Argentina", code: "ar", language: "Español" },
  { country: "Saudi Arabia", code: "sa", language: "العربية" },
  { country: "Iraq", code: "iq", language: "العربية" },
  { country: "Ukraine", code: "ua", language: "Українська" },
  { country: "Poland", code: "pl", language: "Polski" },
  { country: "Türkiye", code: "tr", language: "Türkçe" },
  { country: "Thailand", code: "th", language: "ไทย" },
  { country: "Indonesia", code: "id", language: "Bahasa Indonesia" },
  { country: "Vietnam", code: "vn", language: "Tiếng Việt" },
  { country: "Canada", code: "ca", language: "Français" },
  { country: "Cambodia", code: "kh", language: "ភាសាខ្មែរ" },
  { country: "Kazakhstan", code: "kz", language: "Қазақша" },
  { country: "Uzbekistan", code: "uz", language: "O'zbek" },
];

const Navigation: React.FC<NavigationProps> = ({ 
  className, 
  dropdownBgClass = "glass" // Default to glass background
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [currentLanguage, setCurrentLanguage] = useState(languages[0]); // Default to English
  const isVisible = useOnScrollUp();
  const location = useLocation();
  const { t, i18n } = useTranslation();

  // Effect to set the initial language based on stored preference
  useEffect(() => {
    // Find the language that matches the current i18n language
    const currentCode = i18n.language;
    const foundLanguage = languages.find(lang => {
      const langCode = countryToLanguageMap[lang.code];
      return langCode === currentCode;
    });
    if (foundLanguage) {
      setCurrentLanguage(foundLanguage);
    }
  }, [i18n.language]);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  // Function to handle smooth scrolling to Beats section
  const scrollToBeats = (e: React.MouseEvent) => {
    if (location.pathname === '/') {
      e.preventDefault();
      const beatsSection = document.getElementById('beats');
      if (beatsSection) {
        beatsSection.scrollIntoView({ 
          behavior: 'smooth',
          block: 'start'
        });
      }
      // Close mobile menu if it's open
      if (isMenuOpen) {
        setIsMenuOpen(false);
      }
    }
  };

  // Handle language selection
  const selectLanguage = (lang: typeof languages[0]) => {
    setCurrentLanguage(lang);
    // Set the language in i18next
    const langCode = countryToLanguageMap[lang.code];
    if (langCode) {
      i18n.changeLanguage(langCode);
    }
    // Close mobile menu if it's open
    if (isMenuOpen) {
      setIsMenuOpen(false);
    }
  };

  return (
    <nav 
      className={`fixed top-0 left-0 w-full z-50 glass transition-opacity duration-300 -mt-3 ${
        isVisible ? 'opacity-100' : 'opacity-0'
      } ${className}`}
    >
      <div className="container mx-auto px-6">
        <div className="py-0 flex justify-between items-center">
          <a href="/" className="flex items-center">
            <img src="/lovable-uploads/a12b43ca-3e69-4e2b-ab2e-489603798a23.png" alt="BEATS Logo" className="h-28 w-auto" />
          </a>

          {/* Mobile menu button */}
          <button 
            onClick={toggleMenu} 
            className="lg:hidden rounded-full p-2 focus:outline-none focus:ring-2 focus:ring-gold transition-colors"
          >
            {isMenuOpen ? <X size={24} /> : <Menu size={24} />}
          </button>

          {/* Desktop menu with language dropdown */}
          <div className="hidden lg:flex items-center space-x-5">
            {location.pathname === '/' ? (
              <a 
                href="#beats" 
                onClick={scrollToBeats} 
                className="text-sm hover:text-gold-dark transition-colors cursor-pointer"
              >
                {t('navigation.beats')}
              </a>
            ) : (
              <Link to="/" className="text-sm hover:text-gold-dark transition-colors">{t('navigation.beats')}</Link>
            )}
            <div className="relative group">
              <a href="#solutions" className="text-sm hover:text-gold-dark transition-colors">{t('navigation.solutions')}</a>
              <div className={`absolute left-0 mt-2 w-48 rounded-md shadow-lg ${dropdownBgClass} invisible opacity-0 group-hover:visible group-hover:opacity-100 transition-all duration-300`}>
                <div className="py-2">
                  <Link to="/sound-production" className="block px-4 py-2 text-sm hover:bg-white/20 transition-colors">{t('navigation.soundProduction')}</Link>
                  <Link to="/mix-and-master" className="block px-4 py-2 text-sm hover:bg-white/20 transition-colors">{t('navigation.mixMaster')}</Link>
                  <Link to="/vfx" className="block px-4 py-2 text-sm hover:bg-white/20 transition-colors">{t('navigation.sfx')}</Link>
                </div>
              </div>
            </div>
            <Link to="/support" className="text-sm hover:text-gold-dark transition-colors">{t('navigation.support')}</Link>
            
            {/* Language dropdown */}
            <DropdownMenu>
              <DropdownMenuTrigger className="flex items-center hover:text-gold-dark transition-colors focus:outline-none">
                <Globe size={16} className="mr-1" />
              </DropdownMenuTrigger>
              <DropdownMenuContent className="max-h-60 overflow-y-auto" align="end">
                {languages.map((lang) => (
                  <DropdownMenuItem 
                    key={lang.code}
                    className="flex items-center space-x-2 cursor-pointer text-xs py-1"
                    onClick={() => selectLanguage(lang)}
                  >
                    <img 
                      src={`https://flagcdn.com/20x15/${lang.code}.png`} 
                      alt={`${lang.country} flag`} 
                      width="20" 
                      height="15"
                      className="inline-block"
                    />
                    <span>{lang.language}</span>
                  </DropdownMenuItem>
                ))}
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        </div>

        {/* Mobile menu with language selection */}
        <div className={`lg:hidden absolute w-full left-0 rounded-b-lg shadow-lg bg-white transition-all duration-300 ease-in-out ${
          isMenuOpen ? 'max-h-screen opacity-100 visible' : 'max-h-0 opacity-0 invisible'
        } overflow-hidden`}>
          <div className="px-4 py-2 space-y-3">
            {location.pathname === '/' ? (
              <a 
                href="#beats" 
                onClick={scrollToBeats}
                className="block py-2 text-sm"
              >
                {t('navigation.beats')}
              </a>
            ) : (
              <Link to="/" className="block py-2 text-sm">{t('navigation.beats')}</Link>
            )}
            <div className="space-y-1 ml-4">
              <p className="font-semibold text-sm">{t('navigation.solutions')}</p>
              <Link to="/sound-production" className="block py-1 text-xs">{t('navigation.soundProduction')}</Link>
              <Link to="/mix-and-master" className="block py-1 text-xs">{t('navigation.mixMaster')}</Link>
              <Link to="/vfx" className="block py-1 text-xs">{t('navigation.sfx')}</Link>
            </div>
            <Link to="/support" className="block py-2 text-sm">{t('navigation.support')}</Link>
            
            {/* Mobile language selection */}
            <div className="space-y-1">
              <p className="font-semibold text-sm flex items-center">
                <Globe size={14} className="mr-1" />
                Language
              </p>
              <div className="ml-4 max-h-40 overflow-y-auto">
                {languages.map((lang) => (
                  <button
                    key={lang.code}
                    className="flex items-center space-x-2 py-1 w-full text-left text-xs"
                    onClick={() => selectLanguage(lang)}
                  >
                    <img 
                      src={`https://flagcdn.com/16x12/${lang.code}.png`} 
                      alt={`${lang.country} flag`} 
                      width="16" 
                      height="12"
                      className="inline-block"
                    />
                    <span>{lang.language}</span>
                  </button>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navigation;
