
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

// Import all translation files
import translationEN from './locales/en/translation.json';
import translationES from './locales/es/translation.json';
import translationFR from './locales/fr/translation.json';
import translationZH from './locales/zh/translation.json';
import translationHI from './locales/hi/translation.json';
import translationJA from './locales/ja/translation.json';
import translationRU from './locales/ru/translation.json';
import translationDE from './locales/de/translation.json';
import translationPT from './locales/pt/translation.json';
import translationAR from './locales/ar/translation.json';
import translationUK from './locales/uk/translation.json';
import translationPL from './locales/pl/translation.json';
import translationTR from './locales/tr/translation.json';
import translationTH from './locales/th/translation.json';
import translationID from './locales/id/translation.json';
import translationVI from './locales/vi/translation.json';
import translationKM from './locales/km/translation.json';
import translationKK from './locales/kk/translation.json';
import translationUZ from './locales/uz/translation.json';

// Map of language codes to translations
const resources = {
  en: { translation: translationEN },  // English
  es: { translation: translationES },  // Spanish
  fr: { translation: translationFR },  // French
  zh: { translation: translationZH },  // Chinese
  hi: { translation: translationHI },  // Hindi
  ja: { translation: translationJA },  // Japanese
  ru: { translation: translationRU },  // Russian
  de: { translation: translationDE },  // German
  pt: { translation: translationPT },  // Portuguese
  ar: { translation: translationAR },  // Arabic
  uk: { translation: translationUK },  // Ukrainian
  pl: { translation: translationPL },  // Polish
  tr: { translation: translationTR },  // Turkish
  th: { translation: translationTH },  // Thai
  id: { translation: translationID },  // Indonesian
  vi: { translation: translationVI },  // Vietnamese
  km: { translation: translationKM },  // Khmer
  kk: { translation: translationKK },  // Kazakh
  uz: { translation: translationUZ },  // Uzbek
};

// Map country codes to language codes
export const countryToLanguageMap: Record<string, string> = {
  us: 'en', // United States -> English
  gb: 'en', // United Kingdom -> English
  es: 'es', // Spain -> Spanish
  mx: 'es', // Mexico -> Spanish
  ar: 'es', // Argentina -> Spanish
  fr: 'fr', // France -> French
  ca: 'fr', // Canada -> French
  cn: 'zh', // China -> Chinese
  in: 'hi', // India -> Hindi
  jp: 'ja', // Japan -> Japanese
  ru: 'ru', // Russia -> Russian
  de: 'de', // Germany -> German
  br: 'pt', // Brazil -> Portuguese
  sa: 'ar', // Saudi Arabia -> Arabic
  iq: 'ar', // Iraq -> Arabic
  ua: 'uk', // Ukraine -> Ukrainian
  pl: 'pl', // Poland -> Polish
  tr: 'tr', // Turkey -> Turkish
  th: 'th', // Thailand -> Thai
  id: 'id', // Indonesia -> Indonesian
  vn: 'vi', // Vietnam -> Vietnamese
  kh: 'km', // Cambodia -> Khmer
  kz: 'kk', // Kazakhstan -> Kazakh
  uz: 'uz', // Uzbekistan -> Uzbek
};

i18n
  // Detect user language
  .use(LanguageDetector)
  // Pass the i18n instance to react-i18next
  .use(initReactI18next)
  // Init i18next
  .init({
    resources,
    fallbackLng: 'en',
    debug: process.env.NODE_ENV === 'development',
    interpolation: {
      escapeValue: false, // React already escapes values
    },
    detection: {
      order: ['localStorage', 'navigator'],
      caches: ['localStorage'],
    },
  });

export default i18n;
