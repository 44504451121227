
import React, { useState } from 'react';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";
import { StarBorder } from "@/components/ui/star-border";
import { useTranslation } from 'react-i18next';

const FAQ = () => {
  const [hoveredItem, setHoveredItem] = useState<string | null>(null);
  const [expandedItem, setExpandedItem] = useState<string | null>(null);
  const { t } = useTranslation();

  const handleMouseEnter = (itemId: string) => {
    setHoveredItem(itemId);
    setExpandedItem(itemId); // Auto-expand on hover
  };

  const handleMouseLeave = () => {
    setHoveredItem(null);
    // Don't collapse on mouse leave to keep selected item expanded
  };

  const handleValueChange = (value: string) => {
    setExpandedItem(value === "" ? null : value);
  };

  // FAQ items from translations
  const faqItems = [
    {
      id: "item-1",
      question: t('faq.items.audioFormats.question'),
      answer: t('faq.items.audioFormats.answer')
    },
    {
      id: "item-2",
      question: t('faq.items.streaming.question'),
      answer: t('faq.items.streaming.answer')
    },
    {
      id: "item-3",
      question: t('faq.items.purchase.question'),
      answer: t('faq.items.purchase.answer')
    },
    {
      id: "item-4",
      question: t('faq.items.customProduction.question'),
      answer: t('faq.items.customProduction.answer')
    },
    {
      id: "item-5",
      question: t('faq.items.studioSessions.question'),
      answer: t('faq.items.studioSessions.answer')
    }
  ];

  return (
    <div className="w-full max-w-3xl mx-auto py-4">
      <h2 className="text-2xl font-bold text-center mb-4">{t('faq.title')}</h2>
      <Accordion 
        type="single" 
        collapsible 
        className="space-y-4"
        value={expandedItem || undefined}
        onValueChange={handleValueChange}
      >
        {faqItems.map((item) => {
          const isActive = hoveredItem === item.id || expandedItem === item.id;
          
          return (
            <div
              key={item.id}
              onMouseEnter={() => handleMouseEnter(item.id)}
              onMouseLeave={handleMouseLeave}
              className="transition-all duration-300"
            >
              {isActive ? (
                <StarBorder
                  as="div"
                  color="#E5A530"
                  className="w-full"
                >
                  <AccordionItem value={item.id} className="border-0 overflow-hidden bg-white">
                    <AccordionTrigger className="px-4 py-3 text-sm font-medium hover:text-gold-dark transition-colors text-left">
                      {item.question}
                    </AccordionTrigger>
                    <AccordionContent className="px-4 pb-3 text-sm text-left">
                      {item.answer}
                    </AccordionContent>
                  </AccordionItem>
                </StarBorder>
              ) : (
                <AccordionItem value={item.id} className="border-2 border-black rounded-lg overflow-hidden">
                  <AccordionTrigger className="px-4 py-3 text-sm font-medium hover:text-gold-dark transition-colors text-left">
                    {item.question}
                  </AccordionTrigger>
                  <AccordionContent className="px-4 pb-3 text-sm text-left">
                    {item.answer}
                  </AccordionContent>
                </AccordionItem>
              )}
            </div>
          );
        })}
      </Accordion>
    </div>
  );
};

export default FAQ;
