import React from 'react';
import { 
  YoutubeIcon, 
  InstagramIcon,
  FacebookIcon, 
  TwitterIcon,
  Star
} from 'lucide-react';
import { useTranslation } from 'react-i18next';
import FAQ from './FAQ';
import { Link } from 'react-router-dom';
import { Avatar, AvatarImage, AvatarFallback } from './ui/avatar';
import { useCallback, useEffect, useState } from 'react';
import { BASE_CUSTOMER_COUNT, CUSTOMER_DAILY_INCREMENT, BASE_DATE, calculateCurrentValue } from './CounterSection';

// Get the customer count from CounterSection for consistency
const useCustomerCount = () => {
  const [count, setCount] = useState(BASE_CUSTOMER_COUNT);
  
  useEffect(() => {
    // Calculate initial count
    const calculatedCount = calculateCurrentValue(BASE_CUSTOMER_COUNT, CUSTOMER_DAILY_INCREMENT, BASE_DATE);
    setCount(calculatedCount);
    
    // Update the count periodically
    const interval = setInterval(() => {
      const currentCount = calculateCurrentValue(BASE_CUSTOMER_COUNT, CUSTOMER_DAILY_INCREMENT, BASE_DATE);
      setCount(currentCount);
    }, 86400000); // Check once a day
    
    return () => clearInterval(interval);
  }, []);
  
  return count;
};

const Footer: React.FC = () => {
  const customerCount = useCustomerCount();
  const { t } = useTranslation();
  
  // Convert to number to use in translation
  const customerCountValue = Number(customerCount);
  
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  return (
    <footer className="bg-white border-t border-gray-100">
      <div className="container mx-auto px-4 py-12">
        {/* FAQ Section - Increased spacing to match HowItWorks */}
        <div className="mb-10">
          <div className="text-center mb-6">
            <div className="inline-block px-3 py-1 rounded-full bg-gold-light/20 text-gold-dark text-xs font-medium mb-2">
              {t('faq.title')}
            </div>
            <h2 className="text-3xl md:text-4xl font-display font-bold">{t('faq.title')}</h2>
          </div>
          <FAQ />
        </div>

        <div className="flex flex-col items-center">
          {/* Logo - Now Clickable */}
          <div 
            className="mb-6 flex items-center cursor-pointer" 
            onClick={scrollToTop}
            aria-label="Scroll to top"
          >
            <img src="/lovable-uploads/a12b43ca-3e69-4e2b-ab2e-489603798a23.png" alt="BEATS Logo" className="h-24 w-auto" />
          </div>
          
          {/* Payment Methods Image */}
          <div className="mb-4 flex justify-center w-full">
            <img src="/payment.png" alt="Payment Methods" className="h-6 w-auto mx-auto" />
          </div>
          
          {/* Footer Links - Enhanced rounded corners and gradient background */}
          <nav className="mb-4 w-full">
            <ul className="flex justify-center items-center gap-4">
              <li>
                <Link 
                  to="/about-us"
                  onClick={scrollToTop}
                  className="inline-block px-3 py-1 rounded-xl bg-gradient-to-r from-white to-gray-100 text-black border border-black hover:bg-gradient-to-r hover:from-gray-100 hover:to-white transition-colors text-[10px] uppercase tracking-wider font-sans"
                >
                  <span className="font-bold">{t('footer.links.aboutUs').split(' ')[0]}</span> {t('footer.links.aboutUs').split(' ')[1]}
                </Link>
              </li>
              <li>
                <a 
                  href="https://www.youtube.com/channel/UC7x_0NjD5ipVGBL5_36sdUQ/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="inline-block px-3 py-1 rounded-xl bg-gradient-to-r from-white to-gray-100 text-black border border-black hover:bg-gradient-to-r hover:from-gray-100 hover:to-white transition-colors text-[10px] uppercase tracking-wider font-sans"
                >
                  <span className="font-bold">WATCH</span> US
                </a>
              </li>
              <li>
                <Link 
                  to="/support"
                  onClick={scrollToTop}
                  className="inline-block px-3 py-1 rounded-xl bg-gradient-to-r from-white to-gray-100 text-black border border-black hover:bg-gradient-to-r hover:from-gray-100 hover:to-white transition-colors text-[10px] uppercase tracking-wider font-sans"
                >
                  <span className="font-bold">{t('footer.links.support').split(' ')[0]}</span> {t('footer.links.support').split(' ')[1] || 'US'}
                </Link>
              </li>
            </ul>
          </nav>
          
          {/* New customer testimonial element - Updated user avatars for music artists */}
          <div className="flex flex-col items-center mb-6">
            <p className="text-xs font-sans text-black font-medium text-center mb-2">
              {t('footer.customerCount', { count: customerCountValue })}
            </p>
            
            {/* Users Avatars - Keeping the first one, updating 2nd and 4th to better music artists */}
            <div className="flex -space-x-2 mb-2">
              <Avatar className="border-2 border-white h-8 w-8">
                <AvatarImage src="https://images.unsplash.com/photo-1534528741775-53994a69daeb?w=128&h=128&auto=format&fit=crop" alt="User" />
                <AvatarFallback>U1</AvatarFallback>
              </Avatar>
              <Avatar className="border-2 border-white h-8 w-8">
                <AvatarImage src="https://images.unsplash.com/photo-1522536421511-14c9073df899?w=128&h=128&auto=format&fit=crop" alt="Music Artist" />
                <AvatarFallback>U2</AvatarFallback>
              </Avatar>
              <Avatar className="border-2 border-white h-8 w-8">
                <AvatarImage src="https://images.unsplash.com/photo-1493225457124-a3eb161ffa5f?w=128&h=128&auto=format&fit=crop" alt="Music Artist" />
                <AvatarFallback>U3</AvatarFallback>
              </Avatar>
              <Avatar className="border-2 border-white h-8 w-8">
                <AvatarImage src="https://images.unsplash.com/photo-1516223725307-6f76b9ec8742?w=128&h=128&auto=format&fit=crop" alt="Music Artist" />
                <AvatarFallback>U4</AvatarFallback>
              </Avatar>
              <Avatar className="border-2 border-white h-8 w-8">
                <AvatarImage src="https://images.unsplash.com/photo-1488590528505-98d2b5aba04b?w=128&h=128&auto=format&fit=crop" alt="Music Artist" />
                <AvatarFallback>U5</AvatarFallback>
              </Avatar>
            </div>
            
            {/* Star Rating - Updated with gold color */}
            <div className="flex text-gold-dark">
              <Star className="w-4 h-4 fill-[#E5A530]" />
              <Star className="w-4 h-4 fill-[#E5A530]" />
              <Star className="w-4 h-4 fill-[#E5A530]" />
              <Star className="w-4 h-4 fill-[#E5A530]" />
              <Star className="w-4 h-4 fill-[#E5A530]" />
            </div>
          </div>
          
          {/* Copyright */}
          <p className="text-xs text-gray-400">
            &copy; {new Date().getFullYear()} BEATS. {t('footer.copyright')}
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
